/*
 * @Author: your name
 * @Date: 2021-03-24 21:45:14
 * @LastEditTime: 2021-04-07 21:53:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \fodu-h5\src\pages\login\index.tsx
 */
import { defineComponent, ref, onMounted, Ref } from "@vue/runtime-core";
import { useRouter, useRoute } from 'vue-router'
import { useStore } from "vuex";
import { Toast } from 'vant';

import style from './index.module.less'
import { wxLogin, getPhoneCode, codeLogin, fetchCheckFiled, fetchRegister } from '@/services/login'
import { phoneExp, codeExp } from '@/utils/regexp'
import { getEnvironment } from '@/utils/utils'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore();
    const btnMsg = ref('获取验证码')
    const isTimeRockon = ref(false)
    const verificationCode = ref(60)
    const phone: Ref<number | undefined> = ref() // 手机号
    const code: Ref<number | undefined> = ref() // 手机号
    const isClickCode = ref(false) // 用户是否已经点击了请求验证
    onMounted(() => {
      const { code, state } = route.query
      if (code !== undefined && code !== '') {
        wxLogin({
          code, state
        }).then(res => {
          Toast.success('登陆成功');
          store.commit({
            type: 'changeUserInfo',
            userInfo: res
          })
          setTimeout(() => {
            // router.replace({ path: '/' })
            let backlen = history.length-1;
            history.go(-backlen);
          })
        })
      }
    })
    let timer;
    const getCode = () => {
      if (!phoneExp.test(String(phone.value))) {
        Toast.fail('请输入正确的手机号')
        return;
      }
      if(!isTimeRockon.value){
        getPhoneCode({
          phone: phone.value
        }).then(res => {
          if (res) {
            Toast.success('发送成功')
            verificationCode.value = 60;
            isTimeRockon.value = true;
            timeVerification();
            isClickCode.value = true
          }
        })
      }
    }
    const timeVerification =  () => {
      if(verificationCode.value <= 0){
          verificationCode.value = 60;
          btnMsg.value = '获取验证码'
          isTimeRockon.value = false;
          return;
      }
      timer = setTimeout( () =>{
        verificationCode.value --;
        btnMsg.value = `${verificationCode.value}秒后再试`
          timeVerification();
      },1000)
    }

    const handleReturn  = ()=> {
      router.go(-1)
    }
    // 手机号登录
    const handleClickPhoneLogin = () => {
      if (!isClickCode.value) {
        Toast.fail('请先获取验证码')
        return;
      }
      if (!phoneExp.test(String(phone.value))) {
        Toast.fail('请输入正确的手机号')
        return;
      }
      if (!codeExp.test(String(code.value))) {
        Toast.fail('请输入正确的验证码')
        return;
      }
      fetchCheckFiled({
        key: 'phone',
        val: phone.value
      }).then(res => {
        if (res) {
          codeLogin({
            phone: phone.value,
            code: code.value,
            l_type: 'code'
          }).then(res => {
            Toast.success('登陆成功');
            store.commit({
              type: 'changeUserInfo',
              userInfo: res
            })
            setTimeout(() => {
              router.replace({ path: '/' })
            })
          })
        } else {
          fetchRegister({
            phone: phone.value,
            code: code.value,
            state: 'app_h5'
          }).then(res => {
            Toast.success('登陆成功');
            store.commit({
              type: 'changeUserInfo',
              userInfo: res
            })
            setTimeout(() => {
              router.replace({ path: '/' })
            })
          })
        }
      })
    }

    // 用户点击微信登录
    const handleWechrtLogin = () => {
      location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx81a98c6eccac3e3d&redirect_uri=https://m.fodu.com/login&response_type=code&scope=snsapi_userinfo&state=wx_h5#wechat_redirect";
    }

    return () => (
      <div class={style.container}>
        <div class={style.bg}></div>
        <span >
          <svg class={`${style.returnImg} icon`} aria-hidden="true"  onClick={handleReturn}>
            <use xlinkHref="#iconfanhui1"></use>
          </svg>
        </span>
        <div class={style.content}>
          <div class={style.title}>登录佛度帐号 精彩永相随</div>
          <input type="number" v-model={phone.value} class={style.input} placeholder="请输入手机号"></input>
          <div class={style.codeView}>
            <input type="number" v-model={code.value} class={style.codeInput} placeholder="验证码" />
            <div
              onClick={() => getCode()}
              class={verificationCode.value === 60 ? style.codeBtn : `${style.codeBtn} ${style.codeBtnGray}`}
            >{btnMsg.value}</div>
          </div>
          <div class={style.btn} onClick={handleClickPhoneLogin}>登录</div>
          {
            getEnvironment() &&
            <div class={style.otherLogin}>
              <svg class={`${style.weixin} icon`} aria-hidden="true" onClick={handleWechrtLogin}>
                <use xlinkHref="#iconweixin"></use>
              </svg>
            </div>
          }
        </div>
      </div>
    )
  }
})